<template>
    <div class="order_list main_top" >
           <div class="list_title">{{ $t('key133') }}
               <div class="icon_back flex_center iconfont icon-back-line-free hidden-sm-and-up" @click="toBack()"></div>
           </div>
           <div class="list_content" >
               
               <div class="list_has" >
                   <div class="list_thumb">
                       <div class="list_tabs">
                        <el-tabs v-model="activeName" @tab-click="clickTab">
                            <el-tab-pane :label="i.dictLabel" :name="i.dictValue" v-for="(i,index) in messageTabs" :key="index" >
                                <div class="list_empty flex_col_center" v-if="messageList.length==0">
                                        <div class="empty_icon"><img src="../../assets/image/empty.png" alt=""></div>
                                        <!-- <div class="empty_text">{{ $t('key104') }}</div> -->
                                </div>
                                <div class="list_group " v-else v-for="(item,index) in messageList" :key="index"  @click="chooseItem=item.noticeId">
                                    
                                    <div class="order_content flex"  >
                                        <div class="order_img"><img src="https://c2c.fp.guinfra.com/file/65695a6bdb914b963f5c9d2eXowSLLoW03" alt=""></div>
                                        <div class="order_info">
                                            <div class="order_title flex_sb"> <div>{{ item.noticeTitle }}</div> <div class="order_time">{{ item.createTime }}</div></div>
                                            <div class="price_group flex">
                                                <!-- <div class="price_show"><span>{{ currency.remark }}</span>{{ item.unitPrice }}</div> -->
                                                <div class="price_org" v-html="item.noticeContent " :class="[chooseItem==item.noticeId?'showMore':'']"> </div>
                                                <!-- <div class="count">X {{ item.quantity }}</div> -->
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </el-tab-pane>
                            
                        </el-tabs>
                       </div>
                      
                   </div>
               </div>
           </div>
       </div>
   </template>
   
   <script>
   import { divide } from '../../utils/tool';
   export default {
       components:{
       //    homeHeader,
       },
       data(){
           return{
             
               activeName:'',
               chooseItem:'',
               messageList:[],

               pageIndex:1,
               pageSize:20,
               status:-1,
               orderStatus:-1,
               isLastPage:false,
               messageTabs:[],
               
               }
       },
       created(){
           this.getData()
       },
       methods:{
           getData(){
               let dictType = 'sys_notice_type'
               this.$axiosApi.getDictType({dictType}).then(res=>{
                   if(res.code == 200){   
                      this.messageTabs = res.data
                      this.activeName = res.data[0].dictValue
                      this.getMessage(res.data[0].dictValue)
                   }
               })
           },
           getMessage(type){
            let pageIndex = this.pageIndex
            let pageSize = this.pageSize
            let noticeType = type
            this.$axiosApi.getSysnotice({pageIndex,pageSize,noticeType}).then(res=>{
                   if(res.code == 200){   
                      this.messageList.push(...res.data.list) 
                      this.isLastPage = res.data.isLastPage

                   }
               })
           },
           clickTab(tab,event){
            this.chooseItem = ''
            this.pageIndex = 1
            this.pageSize = 20
            let type = tab.name
            this.messageList = []
            this.getMessage(type)
           },
           toBack(){
             this.$router.back()
           },
           handleScroll() {
               const scrollY = window.scrollY;
               const windowHeight = window.innerHeight;
               const documentHeight = document.documentElement.scrollHeight;
               const bottomOfPage = windowHeight + scrollY >= documentHeight;   
               if (bottomOfPage && !this.isLastPage) {
                   this.pageIndex = this.pageIndex+1
                   this.getMessage()
               }         
           },
         
   
       },
       mounted() {
       window.addEventListener('scroll', this.handleScroll)  
      },
       computed:{
           currency(){ return this.$store.state.currency}
       },
       destroyed() {
           window.removeEventListener('scroll', this.handleScroll)
       },
   }
   </script>
   
   <style lang="scss" scoped>
    .order_list{
       margin-top:16px;
       .list_title{
           width: 100%;
           height: 48px;
           background: #fff;
           border-radius: 4px;
           margin-bottom: 16px;
           color: #0f0f0f;
           font-size: 16px;
           line-height: 24px;
           font-weight: 600;
           padding-left:12px;
           text-align: left;
           line-height: 48px;
       }
       .list_content{
           
           .list_has{
               min-height: 456px;
               border-radius: 4px;
               background:#fff;
               .list_thumb{
                   padding:30px;
                   margin-bottom:16px;
                   .list_group{
                       background:#eff2f5;
                       border-radius: 4px;
                       padding:16px;
                       margin-bottom:16px;
                       
                       .order_content{
                           text-align: left;
                           margin-top:8px;
                           .order_img{
                               width:44px;
                               height: 44px;
                               border-radius: 50%;
                               overflow: hidden;
                               
                               >img{
                                   width: 100%;
                                   height: 100%;
                               }
                           }
                           
                           .order_info{
                               margin-left:8px;
                               flex:1;
                               color:#525252;
                               .order_title{
                                   font-size:16px;
                                   line-height: 20px;
                                   margin-bottom:4px;
                                   .order_time{
                                    font-size:11px;
                                    color:#888888;
                                   }
                               }
                               .price_group{
                                   font-size: 14px;
                                   align-items: baseline;
                                   .price_show{
                                       margin-right:8px;
                                       color:#ff5000;
                                       font-weight: 700;
                                       font-size:20px;
                                       >span{
                                           font-size: 12px;
                                       }
                                   }
                                   .price_org{
                                       font-size: 13px;
                                       color:#3c3c4380;
                                       line-height: 20px;
                                       display: -webkit-box; /* 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
                                        -webkit-box-orient: vertical; /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
                                        text-overflow: ellipsis; /* 可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
                                        -webkit-line-clamp: 2;
                                        overflow : hidden;
                                        transition: all 0.5s ease-in-out;
                                    
                                    //    text-decoration: line-through;
                                   }
                                   .showMore{
                                    -webkit-line-clamp:none!important;
                                   }
                                   .count{
                                       color:#5e626b;
                                       margin-left:16px;
                                   }
                               }
                               .order_status{
                                   margin-top:4px;
                                   .status_name{
                                       .status_item{
                                           padding:4px 14px;
                                           font-size: 12px;
                                           border-radius: 24px;
                                           border:1px solid #fc0;
                                           cursor: pointer;
                                       }
                                   }
                                   .status_time{
                                       color:#5e626b;
                                       font-size: 12px;
                                       .time_name{
                                           color:#0f0f0f;
                                           font-weight: 600;
                                           margin-right:12px;
                                       }
                                   }
                               }
                           }
                       }
                       .list_empty{
                            height: 456px;
                            border-radius: 4px;
                            background:#fff;
                            .empty_icon{
                                width: 160px;
                            } 
                            .empty_text{
                                margin-top: 8px;
                                color: #77808c;
                                font-size: 15px;
                            }
                        }
                   }
               }
           }
       }
   }
   @media screen and (max-width:768px){
       .order_list{
           // padding-top:48px;
           margin-top: 0 !important;;
           .list_title{
             text-align: center;
             height: 44px;
             margin:0 -16px 12px!important;
             line-height: 44px;
             position: relative;
             width: auto;
            
             .icon_back{
               font-size: 20px;
               position: absolute;
               left:16px;
               top:12px;
               height: 20px;
               cursor: pointer;
             }
           }
       }
       .list_has{
        background: none !important;;
       }
       .list_thumb{
           padding: 0 !important;
           background:#eff2f5;
           .list_group {
               background: #fff !important;
               border-bottom:1px solid #ededed;
               margin-bottom:0!important;
           }
       }
   }
   </style>